<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"></meta>
<template>
  <div style="min-width: 1260px">
    <app-main ref="main">
      <!-- 顶部广告 -->
      <template v-slot:main-top>
        <div @click="adClick" class="ad" ref="ad"><img :src="adsimage.localimg" /></div>
      </template>
      <template v-slot:main-content>

        <!-- ----------- step1 --Step3：选择数据来源平台 testarea -------------------------->
        <div class="step" ref="stepThree">
          <p class="stepTitle">第一步：选择快递面单 <span class="ttdesc" id="desc_NoauthSendnum"></span></p>
          <div class="step-body">
            <div @click="platformGetDeliver(item.id)" v-for="item in stepOne" :key="item.id" class="step-body-item2" :class="{ active: stepOneActive == item.id }" :id="item.id" @mouseout="hidePlatformMsg" @mouseover="showPlatformMsg(item.id)">
              <img class="logImg" :src="item.icon" />
              <div class="imgName">{{ item.name }}</div>
            </div>
	    <div class="onemsg" id="onemsg" style="display:none;">视频号、小红书</div>
          </div>
        </div>
        <!-- --------------------------Step2：选择快递公司 发货仓库  testarea -------------------------->
        <div class="step">
          <p class="stepTitle">第二步：选择快递仓库<!-- a class="storageinfo">上架深圳韵达仓库，新增礼品挂钩6*6cm、垃圾袋5卷装。</a --></p>
          <div class="step-body">
            <div :id="item.deliver_tag" @click="deliverSetStorage(item.deliver_tag)" v-for="(item, index) in deliverList" :key="index" class="step-body-item" :class="{ active: deliverActive == item.deliver_tag }">
              <img v-if="item.show_icon === '1'" class="logImg" :src="item.icon" />
             &nbsp; <div class="imgName">{{ item.deliver_name }}</div>
            </div>
          </div>
          <div class="storageinfo2" id="storageinfo2"><span id="deliverInfo"></span></div>
          <div class="storageinfo3" id="storageinfo3"><span id="stopKeyword"></span></div>
          <div class="storageinfo3" id="storageinfo4"><span id="sendInfo"></span></div>
        </div>

        <!-- --------------------------Step2 - step_a：选择发货仓库 testarea ------------------------>
        <div class="storageShow"  style="display: none;" id="storageBox"  @mouseout="hideStorageBox" @mouseover="showStorageBox">
          <ul>
            <li style="background-color:#ffefda;border-bottom:1px solid #D5D5D5;">
              仓库名称
	      <span style="margin:0 2px 0 5px">快递价格</span>
	      <span style="margin-right:15px;">同步软件</span>
            </li>
            <li @click="storeItemClick(item.id, index)" :class="[storeActive == (item.id || 0) ? 'screening-active' : '']" class="item" v-for="(item, index) in storeList" :key="index">
              {{ item.name }} 
	      <span style="margin:0 5px 0 10px">(￥{{ item.deliver_price }})</span>
	      <span v-if="item.softs !== ''" style="margin-right:15px;">{{ item.softs }}</span>
            </li>
          </ul>
        </div>

        <!-- --------------------------Step3：选择要购买的礼品 -------------------------->
        <div class="step">
          <p class="stepTitle">第三步：选择要购买的礼品</p>

          <div class="step-body">
            <div  @click="getStorageGoods" class="step-body-item" :class="{ active: goodsActive == 1 }">
              <img src="~assets/icons/lipin.png" class="logImg"  />
              &nbsp; <div class="imgName">选择礼品</div>
            </div>
            <div v-if="lipinList.goods_images" class="show-goods">
              <div style="width: 100%">
                <img :src="lipinList.goods_images" width="60px" height="60px" />
                <div class="storageinfo2" id="buy_goods_info" style="margin-left:12px;display:inline-block">
                  <img src="~assets/icons/ok.png" width="18px" height="18px" />
                  {{ lipinList.goods_name }}
                  ￥{{ lipinList.goods_price }}
                  重量 {{ lipinList.goods_weight }} g
                </div>
              </div>
            </div>
          </div>
        </div>

        <el-dialog title="选择礼品" :visible.sync="goodsInfoShow" :before-close="goodsInfoClose" top="50px" width="790px">
          <div :model="goodsInfo" :label-position="labelPosition" label-width="100px" style="display: flex; flex-direction: column; align-items: center;">

              <div class="goodswarn" v-show="goodsWarnShow">
	        注意：该仓库到网点自动签收。<br>要求一定收到礼品，请下【实物仓】或【福利品仓】。
	      </div>
              <div class="center" style="display: inline;">
              <!-- 礼品列表 -->
                <div class="dealbox" v-for="(item, index) in goodsLists.length !== 0 ? goodsLists : goodsList" :key="index">
                  <div class='deal'>
                    <img :src="item.goods_images" width="220" height="220" />
                    <div class="goods-title" style="width: 220px; overflow: hidden;white-space: nowrap;text-overflow: ellipsis">{{ item.goods_name }}</div>
                    <div class="good-buy">
                      <div class="buy" @click="buyIt(item)">下单</div>
                      <div class="price" >
                        <em>￥</em><span>{{ item.goods_price }}</span>
                        <a v-if="item.goods_weight != 0">重量{{ item.goods_weight }}g</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <el-pagination v-if="totalPage" background @current-change="goodsHandleCurrentChange" :current-page="goodsCurrentPage" :page-size="goodsPageSize" layout="total, prev, pager, next, jumper" :total="totalPage"></el-pagination>
            </div>
          </el-dialog>
        <!------------------------------- Step4 -------------录入收件人信息------------------------>

        <div class="step">
          <p class="stepTitle">第四步：录入收件人信息（推荐使用“<span style="color:#F00;">智能发货</span>”绑定店铺可批量同步，自动填快递号，自动发货）</p>
          <div class="step-body">
            <div @click="stepFourItemClick(item, index)" v-for="(item, index) in stepTwo" :key="index" class="step-body-item" :class="{ active: stepTwoActive == index }">
              <img class="logImg" :src="item.icon" />&nbsp; 
	      <div class="imgName">{{ item.name }}</div>
            </div>
          </div>
	  <div class="info-w" v-show="shoStoreAuthWarn">
	    注意：手工下单须商家认证 [找客服]:
	    <span v-if="$store.getters.getSiteInfo.wx_service !== ''" v-clipboard:copy="$store.getters.getSiteInfo.wx_service" v-clipboard:success="onCopy" v-clipboard:error="onError">{{ $store.getters.getSiteInfo.wx_service }}<img src="~assets/icons/copy4.png" class="info-w_img2" />，绑定店铺可直接发货
	    </span>
	  </div>
        <!-- 
	  <div class="addrtips" v-show="shoStoreAuthWarn">
	    <B>抖音隐私号示例:</B>（短号放在姓名后面，不要放在电话后面）<br>
	    2292888888888888055，张三-0000，13900000000，浙江省杭州市西湖区江南大道88号
	  </div>
	-->
        </div>

        <!-- 录入收件人信息 '绑定淘宝', '绑定拼多多', :autoSmart="autoSmart?false:true"'一键发货'-->
        <!---------------------------- 模板导入 ---------------------------->
        <el-collapse-transition>
          <div v-show="templateImport" class="selectWay templateImport" style="display:none;">
            <div class="upload-left">
              <el-upload class="upload" drag action accept=".xls, .xlsx, .csv, .xlsm, .xltx" :show-file-list="false" :http-request="uploadFile" multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text"> 将文件拖到此处，或
                  <em>点击上传</em> <br />
                  <span class="upload-span">仅支持CSV、XLS、XLSM、XLTX、XLSX文件</span>
                  <p></p>
                  <span class="upload-span"> 导入地址过多请分批下单，单次条数控制在 <em>100</em>条左右 </span>
                </div>
              </el-upload>
            </div>
            <div class="upload-right">
              <p>注意事项:</p>
              <p>1.从官方平台导出的订单模板，无需任何修改，直接上传即可</p>
              <p>
                2.可选择下载本平台指定模板格式上传，点击
                <a href="/地址模板.xlsx" target="_blank">下载模板</a>
              </p>
              <p>3.仅支持CSV、XLS、XLSM、XLTX、XLSX文件</p>
              <p>4.导入地址过多请分批下单，单子过多可能会造成卡单、无法出单等异常情况(单次条数控制在100条左右)</p>
            </div>
          </div>
        </el-collapse-transition>


        <!---------------------------- 加载数据提示 ---------------------------->
	<div v-show="loadingTips" class="loading-div">
	  <div class="loading-css"></div>
	Loading...
	</div>

        <!----------------------------绑定店铺 ------店铺导入 ---------------------------->
        <el-collapse-transition>
          <div v-show="storeImport" class="selectWay" style="display:none;">
            <div class="store-select">
              <span class="titleshop">{{ bindTitle }}&nbsp;</span>
              <el-select v-model="shopValue" placeholder="请选择绑定的店铺">
                <el-option v-for="item in shopOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
              <span style="margin-left: 16px; color: #1890ff; cursor: pointer" @click="jumpToStoreBind">绑定店铺</span>
            </div>
            <div class="flag-select">
                <el-form label-width="80px" :model="form">
              <span>标记筛选：&nbsp;</span>
              <el-radio-group v-model="radio">
                <el-radio :label="6">全部</el-radio>

                <el-radio :label="index" v-for="(item, index) in flatList" :key="index">
                  <i class="el-icon-s-flag" :style="Bcolor(item)"></i>
                </el-radio>
              </el-radio-group>
                <!-- 查询 -->
		  <span style="margin-left:50px;">订单时间：</span>
		  <el-date-picker
		    class="input"
		    v-model="form.times"
		    value-format="yyyy-MM-dd HH:mm:ss"
		    type="datetimerange"
		    range-separator="至"
		    start-placeholder="开始日期"
		    end-placeholder="结束日期">
		  </el-date-picker>
                </el-form>
            </div>
            <div class="selectWay">
              <!-- 标题 -->
              <div class="list-title">
                <div>
                  <span class="span-one">点击获取订单以获取订单</span>
                  <span class="span-two">
                    共上传<span class="span-three">
                      <b style="color: red; font-size: 16px">{{ addressList.length }}</b> </span
                    >条数据
                  </span>
		  <span class="span-four" id="noauth_warn">{{noauth_warn}}</span>
                </div>
                <div>
                  <el-button @click="getOrderListByStoreNametb" :disabled="flag" style="background-color:#409eff;color:#FFF;">{{ bindTitle }}订单获取</el-button>
                  <!-- <el-button @click="nextStep2" type="primary">信息正确,下一步</el-button> -->
                </div>
              </div>
              <!-- --------------------------订单展示表格 ---------------------------->
              <el-table :header-cell-style="{ background: '#fafafa' }" ref="addressTable2" :data="addressList.slice((currentPage - 1) * pageSize, currentPage * pageSize)" style="width: 100%" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55">
                </el-table-column>
                <el-table-column prop="order" label="订单编号" min-width="12%"></el-table-column>
                <el-table-column prop="nick" label="旺旺名" min-width="10%"></el-table-column>
                <el-table-column prop="title" label="商品名称" min-width="16%"></el-table-column>
                <el-table-column prop="name" label="收货人姓名" min-width="9%"></el-table-column>
                <el-table-column prop="phone" label="手机号" min-width="12%"></el-table-column>
                <el-table-column prop="address" label="收货地址" min-width="31%">
                  <template slot-scope="scope">{{ scope.row.province }} {{ scope.row.area }} {{ scope.row.city }} {{ scope.row.detail }}</template>
                </el-table-column>
                <el-table-column label="操作" min-width="8%" style="nowrap">
                  <template slot-scope="scope">
                    <el-button size="mini" type="danger" @click="handleOrderListDelete(scope.$index + (currentPage - 1) * pageSize + 1, scope.row)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination @current-change="handleCurrentChange" background class="addressListPage" layout="prev, pager, next" :current-page="currentPage" :page-size="pageSize" :total="addressList.length"></el-pagination>
            </div>
          </div>
        </el-collapse-transition>
        
        <!------------------------------- 手工填写--------------------------------- -->
        <el-collapse-transition>
          <div v-show="handWritten" class="selectWay" style="display:none;">
            <el-tabs @tab-click="handleClick" value="first">
              <!-- 地址格式一 -->
              <!--  <el-tab-pane label="地址格式一" name="first"> -->
                <div class="addressOne">
                  <el-form :mode="addForm" ref="addFormRefs" class="addressOne-2">
                    <el-form-item prop="address" style="margin-bottom:0px;">
                      <el-input type="textarea" class="textarea" :autosize="{ minRows: 8 }" v-model="addForm.address"
                        placeholder="订单号, 姓名，电话，地址
订单号, 姓名，电话，地址

（中间用逗号分隔，一个收件人信息一行，回车键换行）
导入地址过多请分批下单，单子过多可能会造成卡单、无法出单等异常情况(单次条数控制在100条左右)" ></el-input>
                    </el-form-item>
                    <el-form-item style="margin-bottom:3px;">
                      <el-button type="primary" size="medium" @click="disAddres2()">添加地址</el-button>
                      <el-button @click="resetForm('addForm')">清空重置</el-button>
                      <el-button @click="showExample()">查看示例</el-button>
                    </el-form-item>
                  </el-form>
                </div>
              <!-- </el-tab-pane> -->
              
            </el-tabs>
            <div class="NotTitle">{{ model.NotTitle }}</div>
          </div>
        </el-collapse-transition>
        <!---------------------------- 收货人信息列表 ---------------------------->
        <div v-if="listTableFlag || addForm.address" class="selectWay">
          <!-- 标题 -->
          <div class="list-title">
            <div>
              <span class="span-one">根据导入内容，核对收件人信息</span>
              <span class="span-two">
                共上传<span class="span-three" style="color: red">
                  <b>{{ addressList.length }}</b> </span
                >条数据
              </span>
              <span class="span-four" id="noauth_warn">{{noauth_warn}}</span>
            </div>
	  <!--
            <div>
              <el-button @click="nextStep" type="primary">信息正确,下一步</el-button>
            </div>
	  -->
          </div>
          <!-- 收货人表格 -->
          <el-table :header-cell-style="{ background: '#fafafa' }" ref="addressTable" :data="addressList.slice((currentPage - 1) * pageSize, currentPage * pageSize)" style="width: 100%" @selection-change="handleSelectionChange">
            <div v-for="(item, i) in addressList" :key="i">{{ item }}</div>

            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column label="订单编号" min-width="14%">
              <template slot-scope="scope">
                <span v-if="scope.row.order != ''">{{ scope.row.order }}</span>
                <span v-else style="color: red">订单号不能为空</span>
              </template>
            </el-table-column>
            <el-table-column label="收货人姓名" min-width="8%">
              <template slot-scope="scope">
                <span v-if="scope.row.name && scope.row.name.length > 15">
		  {{ scope.row.name }}
		  <br />收货人不正确
		</span>
                <span v-else-if="scope.row.name != ''">{{ scope.row.name }}</span>
                <span v-else style="color: red">收货人不能为空</span>
              </template>
            </el-table-column>
            <el-table-column prop="phone" label="手机号" min-width="15%">
              <template slot-scope="scope">
                <span v-if="scope.row.phone < 11">
                  <p style="color: red">
		    {{ scope.row.phone }}
                    <br />手机号格式有误
                  </p>
		</span>
                <span v-else-if="scope.row.phone.length > 18">
                  <p style="color: red">
                    {{ scope.row.phone }}
                    <br />手机号格式有误
                  </p>
                </span>
                <span v-else>
                  {{ scope.row.phone }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="收货地址" min-width="38%">
              <template slot-scope="scope">
                <span v-if="scope.row.province != '' || scope.row.city != '' || scope.row.area != '' || scope.row.detail != ''">{{ scope.row.province }} {{ scope.row.city }}{{ scope.row.area }} {{ scope.row.detail }}</span>
                <span v-else style="color: red">收货人地址不能为空</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" min-width="8%">
              <template slot-scope="scope" style="display: flex;nowrap">
                <el-button size="mini" type="danger" @click="handleDelete(scope.$index + (currentPage - 1) * pageSize + 1, scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @current-change="handleCurrentChange" background class="addressListPage" layout="prev, pager, next" :current-page="currentPage" :page-size="pageSize" :total="addressList.length"> </el-pagination>
        </div>
	

          <!-- 弹出窗口，实例-->
        <el-dialog title="手动输入收货人示例" width="660px" :visible.sync="exampleShow" top="120px" :before-close="exampleClose">
          <div class="addressOne-r">
            <p>注意事项:</p>
            <p>1、订单号, 姓名，电话，地址,  按顺序填写，中间用逗号分隔，一次可以输入多个收件人信息，一个收件人信息一行输入。</p>
            <p>2、如遇东莞市、三沙市、儋州市、嘉峪关市等没有区的城市，区域可设置为“其他区”</p>

            <p>示例:</p>
            <p>2292888888888888055,张三,15100009999,湖北省武汉市江汉区解放大道392号</p>

            <p>抖音隐私号示例:（短号是放在姓名后面，不要放在电话后面）</p>
            <p>2292888888888888055,张三-0000,13900000000,浙江省杭州市西湖区江南大道88号</p>

            <p>京东隐私号示例:</p>
            <p>2292888888888888055,张三,13900000000-0000,浙江省杭州市西湖区江南大道88号</p>

          </div>
        </el-dialog>

        <!-- --------------------------编辑表格Dialog ---------------------------->
        <el-dialog title="编辑收货人信息(手动录入)" width="30%" :visible.sync="dialogFormVisible">
          <el-form class="editForm" :model="editObj" :rules="rules" ref="editObj">
            <el-form-item label="订单编号">
              <el-input v-model="editObj.order" clearable></el-input>
            </el-form-item>
            <el-form-item label="收货人姓名">
              <el-input v-model="editObj.name"></el-input>
            </el-form-item>
            <el-form-item label="手机号码" prop="phone">
              <el-input v-model="editObj.phone" maxlength="11" oninput="value=value.replace(/[^\d]/g,'')" clearable> </el-input>
            </el-form-item>
            <el-form-item label="收货地址">
              <el-input v-model="editObj.province" clearable></el-input>
              <el-input v-model="editObj.city" clearable></el-input>
              <el-input v-model="editObj.area" clearable></el-input>
              <el-input v-model="editObj.detail" type="textarea" clearable></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
            <el-button size="small" type="primary" @click="dialogFormYes">确 定</el-button>
          </div>
        </el-dialog>
        <!-- --------------------------Step5-----填写真实姓名--------------------- -->
	<!-- 
        <el-dialog title="编辑个人信息" :visible.sync="truenameDialogVisible" width="360px" center>
            <span class="content" style="font-size:16px;" >
              真实姓名：
	      <input type="text" name="true_name" id="true_name" class="el-input__inner" style="width:220px;" placeholder="真实姓名">
            </span>
            <div style="color:#999999;text-align:center;padding-top:5px;">
              （您还没填写发货人姓名）
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" size="small" @click="setTruename()">确定</el-button>
            </span>
        </el-dialog>
	-->
        <!-- --------------------------Step5-----提交订单--------------------- -->
        <div class="step three" ref="stepThrees">
          <div class="step-body step-three">
              <div class="sub-order">
                <el-form :model="goodsItem">
                  <div class="btns">
                    <span class="sub-btn" @click="placeOrder" :disabled="flag">提交订单 ￥<span id="totalMoney">0</span></span>
                  </div>
                </el-form>
              </div>
          </div>
        </div>
        <!-- 停发区域Dialog   {{Number(goods.salePrice).toFixed(2)}} @formSubmit='translateText'-->
        <stop-sending :showStopSending="showStopSending" @closeStopSending="closeStopSending" :stopStoreList="stopStoreList" />
        <!-- 付款Dialog -->
        <pay-dialog :dialogShow="dialogShow" @closePayDialog="closePayDialog" :msg="orderMsg" :name="goodsItem.goods_name" :price="orderPrice" :money="money" :orderId="orderId" />
      </template>
    </app-main>
  </div>
</template>
<script>
import { regionData, CodeToText } from 'element-china-area-data'
import AddressParse from 'zh-address-parse' // 地址解析插件
import AppMain from 'components/baseStructure/AppMain'
import PayDialog from 'components/payDialog/PayDialog'
import StopSending from 'components/stopSending/StopSending'
// eslint-disable-next-line no-unused-vars
// 原有 choosePlatform  testarea 2023/03/23
import { testPost, addArea, getAllStorage, getsuperadsbytag, chooseDeliver, storageGoods, getAllGoods, getDisAddres, goodsSearchGs, deliverGs, createOrder, getAreaList, getUserMoney, importExcel, getStoreName, getTaoBaoOrderList, getAllOrderList, resolve, getNoauthSendnum, setUserinfo } from 'network/api'
export default {
  name: 'Purchase',
  components: { AppMain, PayDialog, StopSending },
  inject: ['mainReload'],
  data() {
    var checkNickname = (rule, value, cb) => {
      const regNickname = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (regNickname.test(value)) {
        cb()
      }
      cb(new Error('手机号不合法！'))
    }
    return {
      shoStoreAuthWarn: false,	//显示商家认证提示
      flag: false,
      flag2: false,
      shows: true,
      item: null,
      labelPosition: 'right',
      isShowInfo: true,
      loadingTips: false,	//显示加载数据提示
      is_nosource: 0,
      form: {
        times: []    // 选择时间
      },
      userInfo: {
        send_name: '',
        send_phone: '',
        send_address: '',
        send_city: '',
        send_province: '',
        send_town: ''
      },
      options: regionData,
      userInfoShow: false,
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkNickname, trigger: 'blur' }
        ]
      },
      autoSmart: false, // 智能发货
      innerVisible: false,
      addForm: { address: '' },
      model: {
        NotTitle: '【注意】如因虚拟号提示手机格式错误，可忽略不管直接下单。【温馨提示】导入地址过多请分批下单，单子过多可能会造成卡单、无法出单等异常情况(单次条数控制在100条左右)\r\n【注意】个别订单中的包裹会因疫情或偏远原因无法获取单号，平台会关闭对应订单并退款，可更换其他快递或联系买手修改地址再进行下单。 '
      },
      flatList: [
        { id: 0, key: 1, color: '#ff2900'},
        { id: 1, key: 2, color: '#ffa900'},
        { id: 2, key: 3, color: '#71de2e'},
        { id: 3, key: 4, color: '#196dd1'},
        { id: 4, key: 5, color: '#7529db'}
      ],
      stepOne: [
        { id: 0, key: 0, name: '淘宝菜鸟', icon: require('@/assets/icons/tb.png') },
        { id: 1, key: 1, name: '京东无界', icon: require('@/assets/icons/jd.png') },
        { id: 2, key: 2, name: '拼多多', icon: require('@/assets/icons/pdd.png') },
        { id: 3, key: 3, name: '抖音抖店', icon: require('@/assets/icons/dy.png') },
        { id: 4, key: 4, name: '快手', icon: require('@/assets/icons/qt.png') },
        { id: 5, key: 5, name: '其他', icon: require('@/assets/icons/qt.png') }
//        { id: 5, key: 5, name: '阿里菜鸟', icon: require('@/assets/icons/al.png') },
      ],
      deliverList: [
        { id: 0, key: 0, deliver_tag: 'yt', deliver_name: '圆通', icon: require('@/assets/icons/yt.png') },
        { id: 1, key: 1, deliver_tag: 'zt', deliver_name: '中通', icon: require('@/assets/icons/zt.png') },
        { id: 2, key: 2, deliver_tag: 'yd', deliver_name: '韵达', icon: require('@/assets/icons/yd.png') },
        { id: 3, key: 3, deliver_tag: 'sto', deliver_name: '申通', icon: require('@/assets/icons/sto.png') },
        { id: 4, key: 4, deliver_tag: 'postx', deliver_name: '邮政', icon: require('@/assets/icons/postx.png') },
        { id: 5, key: 5, deliver_tag: 'jt', deliver_name: '极兔', icon: require('@/assets/icons/jt.png') }
      ],
      // stepTwo: ['一键发货', '模板导入', '手工填写', '绑定淘宝店铺', '绑定拼多多', '绑定京东', '绑定抖音'],
      stepTwo: [
        { name: '手工填写', icon: require('@/assets/icons/sd.png') },
//        { name: '一键发货', icon: require('@/assets/icons/a.png') },
        { name: '模板导入', icon: require('@/assets/icons/mb.png') },
        { name: '智能发货', icon: require('@/assets/icons/smart.png') }
      ],
      stepTwoShop: [
        { name: '淘宝店铺', icon: require('@/assets/icons/tb.png') },
        { name: '京东店铺', icon: require('@/assets/icons/jd.png') },
        { name: '拼多多店铺', icon: require('@/assets/icons/pdd.png') },
        { name: '抖音店铺', icon: require('@/assets/icons/dy.png') }
      ],
      // 淘宝菜鸟/阿里菜鸟/抖音抖店/京东无界/拼多多/其他
      stepOneActive: null,
      stepTwoActive: -1,	// 录入方式
      stepTwoActiveShop: -1, // 发货店铺
      templateImport: false, // 控制模板导入相关显示
      storeImport: false, // 控制店铺导入相关显示
      handWritten: false, // 控制手工填写相关显示
      handWrittenAuto: false, // 控制一键发货填写相关显示
      /* 以下为地址数据 */
      textareaAddressValue: '',
      textareaadd: '',
      addressList: [], // 收件人列表
      tmpAddressList: [],	// 临时 收件人列表，selection回显用
      addrChkLength: 0,		// addressList 被选择的记录数量
   //   enteringList: [], // 手动录入地址
   //   addressListTotal: null,
      currentPage: 1, // 当前页
      pageSize: 20, // 每页多少条
      totalPage: 0, // 总商品数
      noauth_warn: '',  // 没实名发件提醒
      listTableFlag: false, // 控制地址列表是否显示
      arsObj: {},
      dialogFormVisible: false, // 控制编辑Dialog显示
    //  truenameDialogVisible: false,	// 填写真实姓名
      editObj: {
        order: undefined,
        name: undefined,
        phone: undefined,
        // address: undefined
        province: undefined,
        area: undefined,
        city: undefined,
        detail: undefined
      }, // 编辑地址对象
      addressListItemIndex: null /* 以下为礼品数据 */, // 选中地址的索引
      /* 以上为地址数据 */
      /* 快递相关 */
      hideBox: false,		// 是否显示仓库下拉窗
      deliverList: [],		// 快递列表
      deliverActive: 0,		// 当前选择快递
      goodsInfoShow: false,	// 控制选择商品Dialog显示
      goodsWarnShow: false,	// 控制选择商品Dialog 的提示信息框显示
      exampleShow: false,	// 控制显示手动输入实例Dialog显示
      storageList: [],		// 临时表，保存所有仓库信息，快递公司分组，页面不刷新存在
      storeList: [],		// 仓库列表
      stopStoreList: [],	// 停发仓库列表
      storeListItem: {},	// 仓库对象
      storeListItemName: '',	// 仓库名字 添加礼品列表需要用到
      storeListItemId: null,	// 仓库id
      sortList: ['默认', '价格从高到低', '销量从高到低', '重量从重到轻'],
      curStorageIsLipin: '0',		// 当前选取的仓库是否为礼品仓
      curStorageIsXinfeng: '0',		// 当前选取的仓库是否为信封仓
      storeActive: 0,
      sortActive: 0,
      goodsPageSize: 6,
      goodsCurrentPage: 1,
      goodsList: [],		// 礼品列表
      goodsItem: {},		// 礼品详情
      goodsActive: 0,
      dataFlag: false,		// true代表选择了礼品, false没有选择礼品
      storageValue: null,	// 运送方式select绑定的数据
      storageOptions: [],	// 运送方式option绑定的数据
      fprice: '',		// 运费
      lipinList: [],
      /* 以上为礼品数据 */
      dialogShow: false,		// 付款Dialog
      showStopSending: false,		// 停发区域
      remarks: '',		// 备注
      areaListValue: '',
      areaList: [],		// 发件人列表
      area_id: '',		// 地址id
      deliver_id: '',		// 快递id
      oid: '',
      oaid: '',			// pdd 使用的
      platform: '',		// 平台
      autoPlatform: true,	// 智能发货平台隐藏
      platform2: '',		// 平台排序号
      money: '',		// 账户余额
      orderId: '',		// 订单id
      bandShop: false,		// 是否有绑定店铺
      bandShopList: [],		// 绑定的店铺
      goodsType: '',		// 商品排序标识
      shopValue: '',		// 选择的店铺value
      shopValueKey: '',		// value中的 key pdd7312624900
      shopOptions: [],
      radio: 6,			// 店铺导入的flag
      orderList: [],		// 店铺导入获取的订单列表
      newList: [],
      newList1: [],
      url: '',
      nav: 0,
      numbers: 1,
      order_from: '',		// 下单方式
      lurufangshi: '',		// 录入方式 -1=一键,0=模板,1=手动,2=tb店,3=pdd店,4=jd店,5=dy店
      lurufangshi_old: '',	// 记录改变前的录入方式，只在选录入方式时改变
      adsimage: '',
      clickhref: '',
      orderFrom: '',		// 绑定 店铺 id 例如 dy10086
      num: 1,
      tblink: '',
      shopId: null,
      timer: null,
      goodsLists: [],
      bindTitle: '淘宝店铺',
      keys: '',
      realname_auth: 0,		// 是否实名认证
      noauth_importnum: 0,	// 没认证会员导入收件人数量限制
      noauth_sendnum: -1,
      have_sendnum: 0,
      have_truename: 0,		// 是否有姓名
      orders: 0,
      goodsInfo: 'goodsInfo',
      stepTwoTmp: null,		// 保存初始面单
      orderPrice: 0,
      orderMsg: ''
    }
  },
  beforeDestroy() {},
  created() {
    this.lurufangshi = -1
    this.lurufangshi_old = -1    

     // 初始化时间选择器
     const dayStar = new Date()
    const dayEnd = new Date()
    dayStar.setTime(dayStar.getTime() - 24 * 3600 * 1000)
    dayEnd.setTime(dayEnd.getTime() + 24 * 3600 * 1000)
    this.form.times = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]

    // 没实名认证会员发单限制
    this.setNoauthSendnum()
    // 初始快递列表
    this.stepTwoDeliver()
    // 顶部广告
    getsuperadsbytag({ tagname: 'purchase' }).then((res) => {
      if(res.data.adslist[0]){
        this.adsimage = res.data.adslist[0]
        this.clickhref = res.data.adslist[0].clickhref
      }
    })
    // 获取所有仓库信息
    this.url = this.$url
  },
  destroyed() {
    // window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  methods: {
    //复选框选中事件
    handleSelectionChange(val) {
	this.tmpAddressList = val;	//每次触发复选事件 存储删除初始为空
	this.setAddrChkLength();

	//this.countPrice;
	//alert(this.addressList.length);
	//alert(this.newList.length);
    },
    // 当前页
    setAddrChkLength() {
      this.addrChkLength = this.tmpAddressList.length;

      this.countTotalMoney();
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val
      this.refreshAddressList()
    },
    handleOrderListDelete(index) {
      var delOrder = this.addressList[index - 1].order;	//获取要删除的记录 order_no

      this.addressList.splice(index - 1, 1)
      this.refreshAddressList()
      this.addrChkLength--

	// 同时，删除临时表对应的记录
      this.tmpAddressList.forEach((item,key)=>{
	if(item.order == delOrder){
	  this.tmpAddressList.splice(key, 1)
	}
      })

      this.countTotalMoney();
    },
    // 删除
    handleDelete(index, row) {
      var delOrder = this.addressList[index - 1].order;	//获取要删除的记录 order_no
      this.addressList.splice(index - 1, 1)

      this.refreshAddressList()
      this.addrChkLength--

	// 同时，删除临时表对应的记录
      this.tmpAddressList.forEach((item,key)=>{
	if(item.order == delOrder){
	  this.tmpAddressList.splice(key, 1)
	}
      })
      this.countTotalMoney();

    },
    //保持页面列表状态
    refreshAddressList(){
      this.tmpAddressList.forEach((el) => {
          for (let i = 0; i < this.addressList.length; i++) {
            if (el.order === this.addressList[i].order) {
                this.$nextTick(() => {	
		  if(this.listTableFlag === true){
                    this.$refs.addressTable.toggleRowSelection(this.addressList[i],true);
		  } else {
		    this.$refs.addressTable2.toggleRowSelection(this.addressList[i],true);
		  }
                })
            }
          }
       })
    },
    //缺省全选
    setOrdersCheck(val){
          for (let i = 0; i < this.addressList.length; i++) {
		this.addressList[i].chk = true;
              this.$nextTick(() => {
		  //判断回显数据与本页addressList数据相匹配，匹配成功勾选框状态变为true
		if(this.listTableFlag === true){
                  this.$refs.addressTable.toggleRowSelection(this.addressList[i],true);
		} else {
		  this.$refs.addressTable2.toggleRowSelection(this.addressList[i],true);
		}
              })
          }
	this.addrChkLength = this.addressList.length
	this.tmpAddressList = this.addressList

	this.countTotalMoney();
    },
    Bcolor(item){
      return `color:${item.color}; font-size: 18px;`
    },
    blur(e, index) {
      this.addressList[index].lp_reissue_sn = e.target.value
    },
    /* 隐藏仓库选择浮窗  testarea */
    hideStorageBox(){
      this.hideBox = true
      setTimeout(function(e){
        if(this.hideBox){
          document.getElementById('storageBox').style.display = "none"
        }
      }.bind(this),400)
    },
    /* testarea testpost	*/
    postOrder(){
	//testPost().then((res) => {})
    },
    /* 设置仓库选择浮窗隐藏状态  testarea */
    showStorageBox(){
      this.hideBox = false
    },
    /* 显示单一快递仓库列表  testarea */
    deliverSetStorage(deliver_tag){
      if( this.platform == '' ){
        return this.$message.warning('请先选择快递面单，谢谢！')
      }
      this.goodsActive = 0		// 控制礼品选择按钮显示
      this.stepTwoActive = null		// 控制录入方式显示
      this.lipinList = []
      this.goodsCurrentPage = 1
      //this.deliverActive = deliver_tag
      this.storeList = this.storageList[deliver_tag]

      const btn = document.getElementById(deliver_tag)
      const box = document.getElementById('storageBox')

      box.style.top = ( btn.offsetTop + 52 ) + "px"
      box.style.left = btn.offsetLeft + "px"
      box.style.display = ""
      this.hideBox = true
    },

    // 选择数据来源平台点击
    // 新系统使用                                   testarea 2023/3/23
    platformGetDeliver(item) {
	// 清空已加载内容，重新加载数据
	this.bandShopList = []
	this.stepOneActive = item
	this.shopValue = ''
	this.storeList = []
	this.deliverActive = 0
	this.storeActive = 0
	this.storeListItem = {}
	this.storeListItemName = ''
	this.storeListItemId = null
	this.goodsList = []
	this.goodsActive = 0
	this.totalPage = 0
	this.lipinList = []
	this.stepTwoActive = null
	// this.addressList = []	// testarea 2023/9/1
	this.storeImport = false
	this.handWritten = false
	this.bandShop = false
	this.curStorageIsLipin = '0'
	this.curStorageIsXinfeng = '0'

	document.getElementById('deliverInfo').innerHTML =  ''
	document.getElementById('sendInfo').innerHTML =  ''
	document.getElementById('stopKeyword').innerHTML =  ''
	document.getElementById('storageinfo2').style.display = 'none'
	document.getElementById('storageinfo3').style.display = 'none'
	document.getElementById('storageinfo4').style.display = 'none'

      switch (item) {
        case 0:
          this.lurufangshi = 2		//对应面单，影响 order_type
          this.stepTwoActive = null
          this.stepTwoActiveShop = item
          this.stepOneActive = item
          this.platform2 = 0		// 平台，对应 platform
          this.platform = 'tb_switch'
          this.bindTitle = '淘宝'

            this.stepTwo = [
              { name: '手工填写', icon: require('@/assets/icons/sd.png') },
//              { name: '一键发货', icon: require('@/assets/icons/a.png') },
              { name: '模板导入', icon: require('@/assets/icons/mb.png') },
              { name: '智能发货', icon: require('@/assets/icons/smart.png') }
            ]
          break
        case 1:
          this.lurufangshi = 4
          this.stepTwoActive = null
          this.stepTwoActiveShop = item
          this.stepOneActive = item
          this.platform2 = 1
          this.platform = 'jd_switch'
          this.bindTitle = '京东'

            this.stepTwo = [
              { name: '手工填写', icon: require('@/assets/icons/sd.png') },
//              { name: '一键发货', icon: require('@/assets/icons/a.png') },
              { name: '模板导入', icon: require('@/assets/icons/mb.png') },
              { name: '智能发货', icon: require('@/assets/icons/smart.png') }
            ]
          break
        case 2:
          this.lurufangshi = 3
          this.stepTwoActive = null
          this.stepTwoActiveShop = item
          this.stepOneActive = item
          this.platform2 = 3
          this.platform = 'pinxixi_switch'
          this.bindTitle = '拼多多'

            this.stepTwo = [
              { name: '手工填写', icon: require('@/assets/icons/sd.png') },
              { name: '模板导入', icon: require('@/assets/icons/mb.png') },
              { name: '智能发货', icon: require('@/assets/icons/smart.png') }
            ]
          break
        case 3:

          this.lurufangshi = 5
          this.stepTwoActive = null
          this.stepTwoActiveShop = item
          this.stepOneActive = item
          this.platform2 = 4
          this.platform = 'dy_switch'
          this.bindTitle = '抖音'

            this.stepTwo = [
              { name: '手工填写', icon: require('@/assets/icons/sd.png') },
//              { name: '一键发货', icon: require('@/assets/icons/a.png') },
              { name: '模板导入', icon: require('@/assets/icons/mb.png') },
              { name: '智能发货', icon: require('@/assets/icons/smart.png') }
            ]
          break
        case 4:

          this.lurufangshi = 7
          this.stepTwoActive = null
          this.stepTwoActiveShop = item
          this.stepOneActive = item
          this.platform2 = 5
          this.platform = 'ks_switch'
          this.bindTitle = '快手'

            this.stepTwo = [
              { name: '手工填写', icon: require('@/assets/icons/sd.png') },
//              { name: '一键发货', icon: require('@/assets/icons/a.png') },
              { name: '模板导入', icon: require('@/assets/icons/mb.png') },
              { name: '智能发货', icon: require('@/assets/icons/smart.png') }
            ]
          break
        case 5:
          this.lurufangshi = 7
          this.stepTwoActive = null
          this.stepTwoActiveShop = item
          this.stepOneActive = item
          this.platform2 = 5
          this.platform = 'others_switch'
          this.bindTitle = '其他'

          this.stepTwo = [
            { name: '手工填写', icon: require('@/assets/icons/sd.png') },
            { name: '模板导入', icon: require('@/assets/icons/mb.png') }
	        ]
          break
        case 6:	
          this.lurufangshi = 6
          this.stepTwoActive = null
          this.stepTwoActiveShop = item
          this.stepOneActive = item
          this.platform2 = 2
          this.platform = 'ali_switch'
          this.bindTitle = '阿里巴巴'

          this.stepTwo = [
            { name: '手工填写', icon: require('@/assets/icons/sd.png') },
            { name: '模板导入', icon: require('@/assets/icons/mb.png') }
          ]
          break
      }
      // 点击平台不同获取仓库列表不同
      chooseDeliver(this.platform, this.keys, this.is_nosource).then((res) => {
        if (res.code === 1 && res.data.length !== 0) {
          // this.storeActive = res.data[0].id
          // this.storeList = res.data
          // this.stopStoreList = res.data
          // this.storeListItem = this.storeList[0] // 初始化仓库对象
          // this.storeListItemId = this.storeList[0].id
          // this.storeListItemName = this.storeList[0].name // 获取到仓库名
          // this.showDeliver() // 快递列表

          // this.deliverActive = res.data[0].deliver_id
          // this.deliverList = res.data

          this.deliverList = null;
          this.storageList = null;
          this.deliverList = res.data.delivers
          // const names = Object.getOwnPropertyNames(res.data.delivers)
          // alert(names[0])

          this.storageList = res.data.data

        } else {
          this.deliverList = null
          // this.totalPage = 0
          // this.goodsPageSize = 0
          // this.storeList = null
          // this.storeListItemName = null
        }
      })
      this.stepTwoTmp = this.stepTwo

      if (this.platform2 === 0 || this.platform2 === 1 || this.platform2 === 3 || this.platform2 === 4) {
          this.getDianpu()
      }
    },
    /*  显示仓库信息 及 提示信息 testarea  */
    setDeliverInfo(item){
      document.getElementById('deliverInfo').innerHTML =  '<img src="'+require('@/assets/icons/ok.png')+'" width="18px" height="18px" style="vertical-align: middle;margin-right:5px;" />'
                          + item.deliver_name + ' - ' 
                          + item.name + '(运费：￥' + item.deliver_price + ')  '
                          + item.city + item.detailed_address
                          + ' 虚假地址请勿下单，真实发货需签收！'
      document.getElementById('storageinfo2').style.display = 'inline-block';

      var send_desc = '每天17点前下单的当天发出，17点后的隔天发出！';
      if(item.send_desc !== '')	send_desc = item.send_desc;

      document.getElementById('sendInfo').innerHTML =  '<img src="http://img.cngift.top/assets/img/send.png" width="18px" height="18px" style="vertical-align: middle;margin-right:5px;" />' + send_desc

      document.getElementById('storageinfo4').style.display = 'inline-block';

	if(item.stop_keyword != ''){
	      document.getElementById('stopKeyword').innerHTML =  '<img src="'+require('@/assets/icons/warning.png')+'" width="18px" height="18px" style="vertical-align: middle;margin-right:5px;" />停发地区：'+ item.stop_keyword
	      document.getElementById('storageinfo3').style.display = 'inline-block';
	} else {
	      document.getElementById('stopKeyword').innerHTML =  ''
	      document.getElementById('storageinfo3').style.display = 'none';
	}
    },
    /*  关闭选择礼品浮窗页时跟新 goodsInfoShow状态 jatc   */
    goodsInfoClose(){
      this.goodsInfoShow = false
      this.goodsLists = []
    },
    exampleClose(){
      this.exampleShow = false
    },
    /* 以下为礼品列表的方法  testarea  */
    // 选择发货仓库
    storeItemClick(id, index) {
      if( this.platform == '' ){
        return this.$message.warning('请先选择快递，谢谢！')
      } else {

	// 如果原来时手动或模板导入，则清空收件人列表
//	if(this.lurufangshi_old == 1 || this.lurufangshi_old == 0){
//	    this.addressList = []
//	}

	if((this.storeList[index].is_lipin == '1') && (this.realname_auth == 0)){
	    return this.$message({
		message: '礼品仓需要实名认证才能发，谢谢！',
		type: 'warning',
		duration:2500,
		center:true,
		showClose:true,
		offset:380
	    });
	}
	if((this.storeList[index].is_xinfeng == '1') && (this.bandShop == false)){
	    return this.$message({
		message: '信封仓需要绑定店铺才能发，谢谢！',
		type: 'warning',
		duration:2500,
		center:true,
		showClose:true,
		offset:380
	    });
	}
	if((this.storeList[index].is_ziti == '1') && (this.bandShop == false || this.realname_auth == 0)){
	    return this.$message({
		message: '自提仓需要实名认证并且绑定店铺才能发，谢谢！',
		type: 'warning',
		duration:2500,
		center:true,
		showClose:true,
		offset:380
	    });
	}

        this.curStorageIsLipin = this.storeList[index].is_lipin
        this.curStorageIsXinfeng = this.storeList[index].is_xinfeng


        this.shopValue = ''	// 需要先选中平台
	this.addressList = []	// 清空地址列表
        this.setDeliverInfo(this.storeList[index])      //选定仓库后的提示信息
        this.goodsLists = []
        this.storeActive = id
        this.storeListItem = this.storeList[index] // 仓库对象
        this.storeListItemName = this.storeList[index].name // 获取到仓库名
        this.storeListItemId = id // 获取到仓库ID
        // this.getGoodsSearchGs() // 根据仓库获取礼品列表
        this.deliverActive = this.storeList[index].deliver_tag

	// 绑定的店铺列表
        this.shopOptions = []
	var pos = 0
        for (let i = 0; i < this.bandShopList.length; i++) {
	  var str = ","+this.storeList[index].soft_ids+","

	  if(this.bandShopList[i][2] != '' && (str.indexOf(","+this.bandShopList[i][2]+",") != -1)){
	    pos++
            this.shopOptions.push({
              label: this.bandShopList[i][0], // 店铺的id 例如 dy10086
              value: this.bandShopList[i][1]  // 此处value未定 等待后续确定
            })
	  }
        }
	if(pos == 0)	this.bandShop = false;	// 当前仓库，没有支持的绑定打单软件

        // 已经绑定店铺，礼品、信封、自提件 不能使用手动及模板导入发货
        if(this.bandShop && ((this.storeList[index].is_lipin == '1') || (this.storeList[index].is_xinfeng == '1') || (this.storeList[index].is_ziti == '1'))){
	    var tmp = []
	    var pos = 0
	    for(let i = 0; i < this.stepTwoTmp.length; i++){

	        if(this.stepTwoTmp[i].name == '手工填写' || this.stepTwoTmp[i].name == '模板导入'){
		    continue
		}
		tmp[pos] = this.stepTwoTmp[i]
		pos++
	    }
	    this.stepTwo = tmp

	} else this.stepTwo = this.stepTwoTmp

        this.hideStorageBox()
      }
    },
    countPrice(){
      this.storageOptions = [] // 添加前清空
      this.fprice = this.storeListItem.deliver_price

      const v = {}
      v.value = this.storeListItem.id
      v.label = this.storeListItem.deliver_name + ' / ' + this.storeListItem.deliver_price + '元'
      v.freight_price = this.storeListItem.deliver_price
      this.storageOptions.push(v)
      this.storageValue = this.storageOptions[0].value // 初始化<el-select>选中
      this.fPrice = this.storageOptions[0].freight_price // 初始化运费
    },
    showExample(){
      this.exampleShow = true
    },
    getStorageGoods(){
      if( this.platform == '' ){
        return this.$message.warning('请先选择快递面单，谢谢！')
      }
      if( this.deliverActive == '' ){
        return this.$message('请先选择快递，谢谢！','warning')
      }
      if( this.storeActive == 0 ){
        return this.$message('请先选择发货仓库，谢谢！','warning')
      }
      this.goodsInfoShow = true
      this.stepTwoActive = -1

      if(this.curStorageIsXinfeng == '1' || this.curStorageIsLipin == '1'){
	this.goodsWarnShow = true
      } else {
	this.goodsWarnShow = false
      }

      storageGoods({
        storage_id: this.storeListItemId,
        goods_type: this.goodsType,
        page: this.goodsCurrentPage,
        op: 'winshop',
        key: this.keys
      }).then((res) => {
        this.goodsList = res.data.data
        this.totalPage = res.data.total
        this.goodsPageSize = res.data.per_page
      })
    },

    // 礼品下单
    buyIt(item) {
      // 首先需要判断是否选择了平台
      if( this.platform == '' ){
        return this.$message.warning('请先选择快递面单，谢谢！')
      }
      if( this.deliverActive == '' ){
        return this.$message('请先选择快递，谢谢！','warning')
      }
      if( this.storeActive == 0 ){
        return this.$message('请先选择发货仓库，谢谢！','warning')
      }
      this.storageOptions = [] // 添加前清空
      this.num = 1
      this.lipinList = [] // 选中的礼品详情
      this.lipinList = item
      // document.getElementById('buy_goods_info').style.display = ''
      this.dataFlag = true
      this.goodsItem = item
      this.fprice = this.storeListItem.deliver_price
      const v = {}
      v.value = this.storeListItem.id
      v.label = this.storeListItem.deliver_name + ' / ' + this.storeListItem.deliver_price + '元'
      v.freight_price = this.storeListItem.deliver_price
      this.storageOptions.push(v)
      this.storageValue = this.storageOptions[0].value // 初始化<el-select>选中
      this.fPrice = this.storageOptions[0].freight_price // 初始化运费

      this.goodsActive = 1
      this.goodsInfoShow = false
      
      this.countPrice()

      /*
      getAreaList().then((res) => {
        this.areaList = res.data // 发件人地址列表  获取到发件人列表 
        for (let i = 0; i < this.areaList.length; i++) {
          if (this.areaList[i].status === '1') {
            this.areaListValue = this.areaList[i].name + ' ' + this.areaList[i].send_phone // 初始化默认发件人地址
            this.area_id = this.areaList[i].id // 初始化发件人地址id
            this.oid = this.areaList[i].oid //
            return
          }
        }
      })
      */
    },

    firstShow() {
      this.handWritten = false
      this.handWrittenAuto = false
      this.addForm.address = ''
      // this.stepTwoActive = index
      this.stepTwoActiveShop = null
      this.stepOne = [
        { id: 0, key: 0, name: '淘宝菜鸟', icon: require('@/assets/icons/tb.png') },
        { id: 1, key: 1, name: '京东无界', icon: require('@/assets/icons/jd.png') },
        { id: 2, key: 2, name: '阿里菜鸟', icon: require('@/assets/icons/al.png') },
        { id: 3, key: 3, name: '拼多多', icon: require('@/assets/icons/pdd.png') },
        { id: 4, key: 4, name: '抖音抖店', icon: require('@/assets/icons/dy.png') },
        { id: 5, key: 5, name: '其他', icon: require('@/assets/icons/qt.png') }
      ]
    },
    stepTwoDeliver() {
      this.handWritten = false
      this.handWrittenAuto = false
      this.addForm.address = ''
      // this.stepTwoActive = index
      this.stepTwoActiveShop = null
      this.deliverList = [
        { id: 0, key: 0, deliver_tag: 'yt', deliver_name: '圆通', icon: require('@/assets/icons/yt.png') },
        { id: 1, key: 1, deliver_tag: 'zt', deliver_name: '中通', icon: require('@/assets/icons/zt.png') },
        { id: 2, key: 2, deliver_tag: 'yd', deliver_name: '韵达', icon: require('@/assets/icons/yd.png') },
        { id: 3, key: 3, deliver_tag: 'sto', deliver_name: '申通', icon: require('@/assets/icons/sto.png') },
        { id: 4, key: 4, deliver_tag: 'postx', deliver_name: '邮政', icon: require('@/assets/icons/postx.png') },
        { id: 5, key: 5, deliver_tag: 'jt', deliver_name: '极兔', icon: require('@/assets/icons/jt.png') }
      ]
    },
    cityChange(v) {
      this.userInfo.send_province = CodeToText[v[0]]
      this.userInfo.send_city = CodeToText[v[1]]
      this.userInfo.send_town = CodeToText[v[2]]
    },
    btns() {
      // this.userInfoShow = false
      // const { city } = this.userInfo
      // this.userInfo.send_city = CodeToText[city[0]]
      // this.userInfo.send_province = CodeToText[city[1]]
      // this.userInfo.send_town = CodeToText[city[2]]

      for (const key in this.userInfo) {
        // 判断值是否为空
        if (!this.userInfo[key]) {
          this.$message({
            message: '请完善信息',
            type: 'warning'
          })
          return false
        }
      }

      if (!/^1[3|4|5|7|8][0-9]{9}$/.test(this.userInfo.send_phone)) {
        this.$message({
          message: '手机号码错误',
          type: 'warning'
        })
      } else {
        // 正确则添加地址
        // let {name,}
        addArea(this.userInfo).then((res) => {
          if (res.code === 1) {
            this.$message({ message: res.msg, type: 'success' })
            if (this.lipinList.length > 0) {
              getAreaList().then((res) => {
                if (res.code === 1) {
                  this.areaList = res.data
                  Object.keys(this.userInfo).forEach((key) => {
                    this.userInfo[key] = ''
                  })
                  // this.areaListValue = this.userInfo.send_address
                }
              })
              this.isShowInfo = false
              setTimeout(() => {
                this.isShowInfo = true
              }, 20)
            }
            this.userInfoShow = false
          }
        })
      }
    },
    // 控制 订单获取 按钮是否可用，当选择了店铺改为可用
    inputInput(e) {
      // const thet = this
      this.flag = true  	//disabled = true 按钮
      if (e.length >= 41) {
        this.handWrittenAuto = true
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.disAddres()
        }, 1000)
        setTimeout(() => {
          this.flag = false
        }, 5000)
      } else if (e.length <= 40) {
        this.addForm.address = ''
        this.addressList = []
        this.listTableFlag = false
        return false
      }
      // this.$refs.import.$div.cliick()
    }, 

    // 没认证会员发快递数量限制
    setNoauthSendnum() {
      getNoauthSendnum().then((res) => {
        if (res.code !== 1) {
          return this.$message.warning(res.msg)
        }
        if (res.code === 1) {	
          this.realname_auth = res.data.realname_auth
          this.noauth_importnum = res.data.noauth_importnum
          this.noauth_sendnum = res.data.noauth_sendnum
          this.have_sendnum = res.data.have_sendnum
          this.have_truename = res.data.have_truename
          this.orders = res.data.orders

          if(this.noauth_sendnum === 0){
            document.getElementById('desc_NoauthSendnum').innerHTML = '（请先实名认证再发快递，谢谢！<a href="/account/realnameAuth">点击这里实名认证>></a> ）'
          } else if(this.noauth_sendnum > 0){
            document.getElementById('desc_NoauthSendnum').innerHTML = '（未实名认证只能发 '+this.noauth_sendnum+' 个“实物仓”快递，<a href="/account/realnameAuth" style="color:#43be2e;font-weight:bold;margin-right:5px;">实名认证</a>发快递更方便！）'
          }
	/*
          if(this.have_truename ===0){
            this.truenameDialogVisible = true
          }
	*/
        }
      })
    },

    allGoods() {
      getAllGoods({ page: this.goodsCurrentPage, op: 'winshop' }).then((res) => {
        if (res.code !== 1) {
          return this.$message.warning(res.msg)
        }
        if (res.code === 1) {
          this.goodsList = res.data.data
          this.totalPage = res.data.total
          this.goodsPageSize = res.data.per_page
        }
      })
    },
    // 商品筛选请求
    getGoodsSearchGs() {
      goodsSearchGs({
        storage_id: this.storeListItemId,
        goods_type: this.goodsType,
        page: this.goodsCurrentPage,
        op: 'winshop',
        key: this.keys
      }).then((res) => {
        this.goodsList = res.data.data
        this.totalPage = res.data.total
        this.goodsPageSize = res.data.per_page
      })
    },
    // 录入收件人信息的方式点击
    stepFourItemClick(item, index) {
      // 首先需要判断是否选择了平台
      if( this.platform == '' ){
        return this.$message.warning('请先选择快递面单，谢谢！')
      }
      if( this.deliverActive == '' ){
        return this.$message('请先选择快递，谢谢！','warning')
      }
      if( this.storeActive == 0 ){
        return this.$message('请先选择发货仓库，谢谢！','warning')
      }
      if( this.goodsActive == 0 ){
        return this.$message('请先选择礼品，谢谢！','warning')
      }

      // this.autoPlatform = true // 智能发货隐藏平台
      // this.stepOneActive = null
      this.shopValue = '' // 需要先选中平台
      // this.stepTwoActive = null
      this.handWritten = false
      this.storeImport = false
      this.templateImport = false
      this.handWrittenAuto = false
      this.addrChkLength = 0
      document.getElementById('totalMoney').innerHTML = 0;
      switch (item.name) {
        case '智能发货':
	  this.shoStoreAuthWarn = false		//显示商家认证提示
          // this.storeActive = 1   //记录选择的礼品
          this.autoSmart = !this.autoSmart
        //  this.storeImport = !this.storeImport
          this.templateImport = false
          this.addForm.address = ''
          this.listTableFlag = false

          // index 0=手工填写 1=一键发货 2=模板导入 3=智能发货
          this.lurufangshi = 3
          this.stepTwoActive = 2	// 输入方式选择按钮 智能取消选择后 锁定到手动 0
          this.storeImport = true
          this.handWritten = false
	  this.listTableFlag = false

            // 旗帜 颜色
            // 其他 1~5 (红黄绿蓝紫)
            // 抖音 0~5 (灰紫青绿橙红)
          if(this.platform2 === 4){
            this.flatList = [
              { id: 0, key: 0, color: 'grey'},
              { id: 1, key: 1, color: '#b434eb'},
              { id: 2, key: 2, color: '#00FFFF'},
              { id: 3, key: 3, color: 'green'},
              { id: 4, key: 4, color: '#FFA500'},
              { id: 5, key: 5, color: 'red'}
            ]
          } else if(this.platform2 === 1){	//京东
            this.flatList = []
          } else {
		// 淘宝 拼多多 旗帜  1~5 (红黄绿蓝紫)
            this.flatList = [
              { id: 0, key: 1, color: 'red'},
              { id: 1, key: 2, color: '#ffa900'},
              { id: 2, key: 3, color: '#71de2e'},
              { id: 3, key: 4, color: '#196dd1'},
              { id: 4, key: 5, color: '#7529db'}
            ]
          }
          break;
        case '手工填写':
	  this.shoStoreAuthWarn = true		//显示商家认证提示
          this.handWritten = true
          this.autoSmart = false
          this.addForm.address = ''
          // index 0=手工填写 1=一键发货 2=模板导入 3=智能发货
          this.lurufangshi = 1
          this.stepTwoActive = 0
          // this.stepTwoActiveShop = null
          break
        case '一键发货':
	  this.shoStoreAuthWarn = false		//显示商家认证提示
          this.handWrittenAuto = true
          this.autoSmart = false
          this.addForm.address = ''
          this.lurufangshi = -1
          this.stepTwoActive = 1 // 手动选中的平台名字
          // this.stepTwoActiveShop = -1 // 店铺平台
          break
        case '模板导入':
	  this.shoStoreAuthWarn = true		//显示商家认证提示
          this.templateImport = true
          this.autoSmart = false
          this.addForm.address = ''
          this.lurufangshi = 0
          this.stepTwoActive = 1
          // this.stepTwoActiveShop = null
          break
      }
      if(this.lurufangshi != this.lurufangshi_old){
	  this.addressList = [] // 订单地址置空 testarea
      }
      this.lurufangshi_old = this.lurufangshi

    },
    getDianpu() {
      getStoreName({ bandType: this.platform2 }).then((res) => {
        if (res.code === 0) {
          this.shopOptions = []
        }
        if (res.code !== 1) {
          return false
        }
        this.shopOptions = []
	this.bandShopList = res.data
	/*
        for (let i = 0; i < res.data.length; i++) {
          this.shopOptions.push({
            label: res.data[i][0], // 店铺的id 例如 dy10086
            value: res.data[i][1]  // 此处value未定 等待后续确定
          })
        }*/
	if(res.data.length > 0)		this.bandShop = true;
      })
    },
    jumpToStoreBind() {
      this.$router.push('storeBind')
    },
    resetForm() {
      this.addForm.address = ''
      this.addressList = []
      this.goodsCurrentPage = 1
      this.listTableFlag = false // 显示地址列表
    },
    // 替换直辖市
    addCheck(str) {
      const a = str.substr(0, 3)
      if (a === '北京北' || a === '天津天' || a === '重庆重' || a === '上海上') {
        const returnStr = str.substr(3) // 北京北
        let c = ''
        const d = a.substr(2) // 北
        c = a.slice(0, -1) // 北京
        c += '市' // 市
        c += d // 北
        c += returnStr //
        return c
      } else {
        return str
      }
    },
    disAddres() {
      if (this.addForm.address === '') {
        this.addressList = []
        return false
      }
      this.tmpAddressList = []

      const addForm = this.addForm.address
      const selfMotion = 'selfMotion'
      getDisAddres({ address: addForm, op: selfMotion }).then((res) => {
        if (res.code !== 1) {
          return this.$notify.warning({ title: res.msg, offset: 90, duration: 4000 })
        } else {
          this.$notify.success({ title: res.msg, offset: 100, duration: 2000 })
        }
        if (res.code === 1 && res.data !== '') {
          this.addressList = []
	  const pos = 0
          res.data.forEach((item, key) => {
            this.listTableFlag = true // 显示地址列表
            this.arsObj = item
            this.arsObj.order = item.order
            this.arsObj.address = item.province + item.city + item.area + item.detail + item.name
            
            this.arsObj.chk = false

            this.addressList[key] = this.arsObj
          })
          this.setOrdersCheck(true);
          this.countPrice();
        }
        if (this.addressList.length !== 0) {
          for (let i = 0; i < this.addressList.length; i++) {
            if (this.addressList[i].name === '' || this.addressList[i].phone === '' || this.addressList[i].address === '' || this.addressList[i].order === '') {
              return this.$message.warning('第' + (i + 1) + '条收件人数据不全')
            }
	    if (this.addressList[i].name.length > 15) {
		return this.$message.warning('第' + (i + 1) + '条收件人姓名有误')
	    }
          //  if (!/^1[3|4|5|6|9|7|8][0-9]{9}$/.test(this.addressList[i].phone)) {
          //    return this.$message.warning('第' + (i + 1) + '条收件人手机号有误')
          //  }
          }
        }
      })
    },
    disAddres2() {
      this.flag2 = true
      setTimeout(() => {
        this.flag2 = false
      }, 5000)

      if (this.addForm.address === '') {
        this.addressList = []
        return false
      }
      this.tmpAddressList = []
      this.loadingTips = true

      // 没实名会员只能导入n行收件人信息
      var addForm = this.addForm.address
      var areaArr = addForm.split("\n");

      if((this.realname_auth == 0) && (areaArr.length > this.noauth_importnum)){

	addForm = ''
        for (let i = 0; i < this.noauth_importnum; i++) {
	  addForm += areaArr[i] + "\n"
        }
      }

      const manual = 'manual'
      getDisAddres({ address: addForm, op: manual }).then((res) => {
        if (res.code !== 1) {
          this.$notify.warning({ title: res.msg, offset: 90, duration: 4000 })
          this.loadingTips = false
          return false
        } else {
          this.$notify.success({ title: res.msg, offset: 100, duration: 2000 })
        }
        if (res.code === 1 && res.data !== '') {
          res.data.forEach((item, index) => {
            if (!item.order) {
              res.data[index].order = this.randomOrder()
            }
          })
          this.addressList = []
	  var pos = 0
	  var showwarning = false
          res.data.forEach((item, key) => {
	    if(this.noauth_sendnum != '-1' && (pos >=this.have_sendnum)){
		if(showwarning == false){
		  var msg = '没实名认证会员只能发 ' + this.noauth_sendnum + ' 个快递'
		  if(this.noauth_sendnum > this.have_sendnum){
		    msg += '，您已经发了 '+(this.noauth_sendnum-this.have_sendnum)+' 个快递'
		  }
		  this.$message({message:msg,offset:200, duration: 3000})
		  this.noauth_warn = msg
		  showwarning = true
		}

		return
	    }

            this.listTableFlag = true // 显示地址列表
            this.arsObj = item
            this.arsObj.order = item.order
            this.arsObj.address = item.province + item.city + item.area + item.detail + item.name

            this.arsObj.chk = false

            this.addressList[key] = this.arsObj

	    pos++
          })
          this.setOrdersCheck(true);
          this.countPrice();
        }
        this.loadingTips = false

        if (this.addressList.length !== 0) {
          for (let i = 0; i < this.addressList.length; i++) {
            if (this.addressList[i].name === '' || this.addressList[i].phone === '' || this.addressList[i].address === '' || this.addressList[i].order === '') {
              return this.$message.warning('第' + (i + 1) + '条收件人数据不全')
            }

            if (this.addressList[i].name.length > 15) {
              return this.$message.warning('第' + (i + 1) + '条收件人姓名有误')
            }

          //  if (!/^1[3|4|5|6|9|7|8][0-9]{9}$/.test(this.addressList[i].phone)) {
          //    return this.$message.warning('第' + (i + 1) + '条收件人手机号有误')
          //  }
          }
        }
      })
    },
    // 订单为空时生产随机单号
    randomOrder() {
      const date = new Date()
      const yar = date.getFullYear()
      const m = [date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1]
      const d = date.getDate().toString().padStart(2, 0)
      const h = date.getHours().toString().padStart(2, 0)
      const n = date.getMinutes().toString().padStart(2, 0)
      const s = date.getSeconds().toString().padStart(2, 0)
      const rand = Math.floor(Math.random() * (99999 - 10000)) + 10000
      const order = `${yar}${m}${d}${h}${n}${s}${rand}`

      return order
    },
    // 地址格式二
    addAddressTwo() {
      const v = this.arsObj.name + this.arsObj.phone + this.arsObj.province + this.arsObj.city + this.arsObj.area + this.arsObj.detail + this.arsObj.order
      const a = AddressParse(v)
      if (!this.arsObj.name || !this.arsObj.phone || !this.arsObj.province || !this.arsObj.city || !this.arsObj.area || !this.arsObj.detail || !this.arsObj.order) {
        this.$message({ message: '请完善信息', type: 'warning' })
      } else if (a.name === '' || a.phone === '' || a.province === '' || a.city === '' || a.area === '' || a.detail === '' || a.order === '') {
        this.$message({ message: '输入地址格式错误!', type: 'warning' })
      } else {
        this.listTableFlag = true // 显示地址列表
        // this.arsObj.order = this.arsObj.order ? this.arsObj.order : this.randomNo(5)
        this.arsObj.address = this.arsObj.province + this.arsObj.city + this.arsObj.area + this.arsObj.detail
        this.addressList.push(this.arsObj)
        this.arsObj = {}
      }
    },
    // 旗帜筛选 tb
    getOrderListByStoreNametb() {
      //this.flag = true
      if (this.radio === 6) {
        const a = null
        this.getdingtb(a)
        this.addressList = this.newList
      } else {
	var flag = this.radio;
	if(this.platform2 == 0 || this.platform2 == 3)	  flag = flag+1;
        this.getdingtb(flag)
        this.addressList = this.newList
      }
      setTimeout(() => {
        this.flag = false
      }, 5000)
    },
    getdingtb(zhi) {
      this.newList = []
      if (!this.shopValue) {
        this.$message({ message: '请先勾选已经完成绑定的店铺', type: 'warning' })
      }
      this.loadingTips = true
      getTaoBaoOrderList({ tbseller: this.shopValue, seller_flag: zhi, createtime: this.form.times }).then((res) => {
        if (res.code !== 1){
	  this.loadingTips = false
	  return this.$notify.warning({ title: res.msg })
        }
        if (res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.newList.push({
              name: res.data[i].receive.receiver_name,
              address: res.data[i].receive.receiver_state + res.data[i].receive.receiver_city + res.data[i].receive.receiver_oaid + res.data[i].receive.receiver_district + res.data[i].receive.receiver_address,
              province: res.data[i].receive.receiver_state,
              city: res.data[i].receive.receiver_city,
              area: res.data[i].receive.receiver_district,
              phone: res.data[i].receive.receiver_mobile,
              detail: res.data[i].receive.receiver_address,

              name_encode: res.data[i].receive.receiver_name_encode,
              phone_encode: res.data[i].receive.receiver_mobile_encode,
              detail_encode: res.data[i].receive.receiver_address_encode,

              order: res.data[i].receive.tid,
              oaid: res.data[i].receive.oaid,
              nick: res.data[i].receive.buyer_nick,
              title: res.data[i].oid.title,
              seller_flag: res.data[i].receive.seller_flag,
              ecode_id: res.data[i].receive.ecode_id,
              trades: res.data[i].receive.trades,
	      chk: false
            })
          }
//        this.addressList = this.newList
	  this.setOrdersCheck(true);
	  this.countPrice();
        }
        this.loadingTips = false
      })
    },
    // 旗帜筛选 jd  dy pdd
    getOrderListByStoreName() {
      this.flag = true
      if (this.radio === 6) {
        const a = null
        this.getding(a)
        this.addressList = this.newList
      } else {
        this.getding(this.radio)
        this.addressList = this.newList
      }
      setTimeout(() => {
        this.flag = false
      }, 5000)
    },
    getding(zhi) {
      this.newList = []
      if (!this.shopValue) {
        this.$message({ message: '请先勾选已经完成绑定的店铺', type: 'warning' })
      }
      getAllOrderList({ pddSeller: this.shopValue, seller_flag: zhi }).then((res) => {
        if (res.code !== 1) return this.$notify.warning({ title: res.msg })
        if (res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.newList.push({
              name: res.data[i].receive.receiver_name,
              address: res.data[i].receive.receiver_state + res.data[i].receive.receiver_city + res.data[i].receive.receiver_district + res.data[i].receive.receiver_address,
              province: res.data[i].receive.receiver_state,
              city: res.data[i].receive.receiver_city,
              area: res.data[i].receive.receiver_district,
              phone: res.data[i].receive.receiver_mobile,
              detail: res.data[i].receive.receiver_address,
              // name: '郭芳芳', // address: '天河区', // province: '广东省', // city: '广州市', // area: '东发过的fgdfgdfg', // phone: '13699998888', // detail: '54543543', // order: '11111',
              order: res.data[i].receive.tid,
              nick: res.data[i].buyer_nick,
              title: res.data[i].oid.title,
              seller_flag: res.data[i].receive.seller_flag,
              ecode_id: res.data[i].receive.ecode_id,
              trades: res.data[i].receive.trades,
	      chk: false
            })
          }
          this.addressList = this.newList
          this.setOrdersCheck(true);
          this.countPrice();
        }
      })
    },
    /* 以下为地址列表的方法 */
    // 重新上传
    uploadAgain() {
      this.listTableFlag = false
      this.addressList = []
      this.arsObj = {}
      this.editObj = {}
      switch (this.stepTwoActive) {
        case 0:
          this.templateImport = true
          break
        case 1:
          break
        case 2:
          this.textareaAddressValue = ''
          break
      }
    },
    // 信息正确,下一步
    nextStep() {
      const a = this.$refs.ad.offsetHeight // 顶部广告位的高度
      const o = this.$refs.stepThree.offsetTop // 距离父元素顶部的高度
      this.$refs.main.$parent.$parent.$refs.main.scrollTop = o - a
    },
    nextStep2() {
      const a = this.$refs.ad.offsetHeight // 顶部广告位的高度
      const o = this.$refs.stepThrees.offsetTop // 距离父元素顶部的高度
      this.$refs.main.$parent.$parent.$refs.main.scrollTop = o - a
    },
    // Dialog 确定
    dialogFormYes() {
      this.dialogFormVisible = false
      // 修改原数组地址
      this.addressList.splice(this.addressListItemIndex, 1, this.editObj)
    }, // 仓库筛选点击
    /* 以上为地址列表的方法 */

    // 排序筛选点击
    sortItemClick(index) {
      this.sortActive = index
      switch (index) {
        case 0:
          this.goodsType = ''
          if (!this.storeListItemId) {
            this.allGoods()
          } else {
            this.getGoodsSearchGs()
          }
          break
        case 1:
          this.goodsType = 'goods_price'
          this.getGoodsSearchGs()
          break
        case 2:
          this.goodsType = 'goods_num'
          this.getGoodsSearchGs()
          break
        case 3:
          this.goodsType = 'goods_weight'
          this.getGoodsSearchGs()
          break
      }
    },
    // 设置发货人真实姓名
    setTruename(){
      var true_name = document.getElementById('true_name').value
      if(true_name =='')        this.$message.error('请填入真实姓名')

      setUserinfo({
        true_name: true_name
      }).then((res) => {
        if (res.code == 1) {
          // this.truenameDialogVisible = false
	  this.have_truename = 1
        }
      })
    },
    // 添加礼品
    addGoods(item) {
      // 首先需要判断是否选择了平台
      if (this.stepOneActive == null) {
        return this.$message({ message: '请先选择平台', type: 'warning' })
      }
      if (this.storeActive == null) {
        return this.$message({ message: '请先选择仓库', type: 'warning' })
      }
      this.storageOptions = [] // 添加前清空
      this.num = 1
      this.lipinList = [] // 选中的礼品详情
      this.lipinList.push(item)
      this.dataFlag = true
      this.goodsItem = item
      this.fprice = this.storeListItem.deliver_price
      const v = {}
      v.value = this.storeListItem.id
      v.label = this.storeListItem.deliver_name + ' / ' + this.storeListItem.deliver_price + '元'
      v.freight_price = this.storeListItem.deliver_price
      this.storageOptions.push(v)
      this.storageValue = this.storageOptions[0].value // 初始化<el-select>选中
      this.fPrice = this.storageOptions[0].freight_price // 初始化运费
      /*      testarea
      getAreaList().then((res) => {
        this.areaList = res.data // 发件人地址列表   获取到发件人列表 
        for (let i = 0; i < this.areaList.length; i++) {
          if (this.areaList[i].status === '1') {
            this.areaListValue = this.areaList[i].name + ' ' + this.areaList[i].send_phone // 初始化默认发件人地址
            this.area_id = this.areaList[i].id // 初始化发件人地址id
            this.oid = this.areaList[i].oid //
            return
          }
        }
      })
      */
    },
    // 分页 当前页
    goodsHandleCurrentChange(val) {
      this.goodsCurrentPage = val
      // this.allGoods()
      this.getGoodsSearchGs()
    },
    /* 以上为礼品列表的方法 */
    // 提交订单
    placeOrder() {
      /*
      if (this.have_truename === 0) {
        this.truenameDialogVisible = true
        return false
      }
	*/
      this.flag = true
      // 判断编辑后的地址格式是否正确
      if (this.tmpAddressList.length !== 0) {
        for (let i = 0; i < this.tmpAddressList.length; i++) {
          if (this.tmpAddressList[i].name === '' || this.tmpAddressList[i].phone === '' || this.tmpAddressList[i].address === '') {
            this.$message.error('第' + (i + 1) + '条收件人数据不全')
            return false
          }

          if (this.tmpAddressList[i].name.length > 15) {
            return this.$message.warning('第' + (i + 1) + '条收件人姓名有误')
          }

          // if (!(/^1[3|4|5|6|9|7|8][0-9]{9}$/.test(this.tmpAddressList[i].phone))) {
          //   this.$message.error('第' + (i + 1) + '条收件人手机号有误')
          //   return false
          // }
        }
      }
      if (!this.dataFlag) {
        return false // this.$message.error('请选择礼品')
      } else if (this.tmpAddressList.length === 0) {
        this.$message.error('请至少填写一个收货地址')
      }
      /* 
      else if (this.areaList.length === 0 || this.areaListValue === '') {
        this.$message.error('请选择发货人地址')
      } */
      else {
        // 获取账户余额
        getUserMoney().then((res) => {
          if (res.code !== 1) return this.$message(res.msg)
          if (res.code === 1) {
            this.money = res.data.usermoney
          }
        })
        setTimeout(() => {
          this.flag = false
        }, 3000)
        const a = this.tmpAddressList
        const postData = {}
        postData.real_list = []
        for (let i = 0; i < a.length; i++) {
          const item = {}
          item.third_order_no = a[i].order
          item.receive_name = a[i].name
          item.receive_phone = a[i].phone
          item.receive_province = a[i].province
          item.receive_city = a[i].city
          item.receive_town = a[i].area
          item.receive_address = a[i].detail
          item.receive_oaid = a[i].oaid
	  
          item.receive_name_encode = a[i].name_encode
          item.receive_phone_encode = a[i].phone_encode
          item.receive_address_encode = a[i].detail_encode

          item.ecode_id = a[i].ecode_id
          item.trades = a[i].trades
          if (a[i].lp_reissue_sn) {
            item.lp_reissue_sn = a[i].lp_reissue_sn
          } else {
            item.lp_reissue_sn = null
          }
          postData.real_list.push(item)
        }
        postData.goods_id = this.goodsItem.id
        postData.storage_id = this.storeListItemId
        postData.order_tips = this.remarks
        postData.goods_num = this.num
        postData.area_id = this.area_id
        postData.oaid = this.oaid
        postData.ecode_id = this.ecode_id
        postData.trades = this.trades
        // eslint-disable-next-line eqeqeq
        if (this.lurufangshi == 4 && this.platform2 == 1) {
          // 京东店铺导入
          postData.platform = 1 // 京东平台
        } else {
          postData.platform = this.platform2
        }
        postData.key = this.keys
        postData.order_from = this.order_from
        // 绑定的店铺id
        postData.shopValue = this.shopValue
        if (this.shopValue) {
          const _this = this
          this.shopOptions.forEach(function (item) {
            if (item.value === _this.shopValue) {
              postData.order_from = item.label
            }
          })
        }
	//lurufangshi录入方式：-1=一键,0=模板,1=手动,2=tb店,3=pdd店,4=jd店,5=dy店
	//platform2 平台: 0=淘宝,1=京东,2=阿里巴巴,3=拼多多,4=抖音,5=其他
        if (this.lurufangshi >= 2) {
          if (this.platform2 == 0)		postData.order_type = 2
          else if (this.platform2 == 1)		postData.order_type = 4
          else if (this.platform2 == 3)		postData.order_type = 3
          else if (this.platform2 == 4)		postData.order_type = 5
          else if (this.platform2 == 5)		postData.order_type = 7
          else if (this.platform2 == 6)		postData.order_type = 8
          else					postData.order_type = this.lurufangshi
        } else {
          postData.order_type = this.lurufangshi
        }

        createOrder(postData).then((res) => {
          if (res.code !== 1) this.$notify.success({ title: res.msg, offset: 50, duration: 15000 })
          if (res.code === 1) {
            this.orderId = res.data.order_id
            this.lp_reissue_sn = res.data.lp_reissue_sn
            this.orderPrice = res.data.order_price
            this.orderMsg = res.msg
            this.dialogShow = true
            this.$notify.success({ title: res.msg, offset: 100, duration: 5000 })
          } else {
            return this.$notify.error({ title: res.msg, offset: 100, duration: 15000 })
          }
        })
      }
    },
    // 通过监听子组件发送过来的事件关闭Dialog// 重置
    closePayDialog() {
      this.$router.push('/orderAdmin/purchase')
      this.mainReload()
      this.inject()
    },
    // 停发区域Dialog关闭回调
    closeStopSending() {
      this.showStopSending = false
    },
    // 查看停发
    seeSending() {
      this.showStopSending = true
    },
    // 仓库选择框
    storageSelectChange(e) {
      this.fprice = e.freight_price
    },
    // 发件地址选择框// 改变发件人地址id
    areaSelectChange(e) {
      this.area_id = e
    },
    // 手工填写 ---> 地址格式切换
    handleClick() {
      this.listTableFlag = false
      this.arsObj = {}
      this.addressList = []
    },
    // 随机生成订单编号
    randomNo(j) {
      var randomno = ''
      for (var i = 0; i < j; i++) {
        randomno += Math.floor(Math.random() * 10)
      }
      randomno = new Date().getTime() + randomno
      return randomno
    },
    // 新建地址点击
    mesBox() {
      this.$confirm('前往添加发件地址, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.push('/orderAdmin/sendAddress')
      })
    },
    /** * 模板导入 * 自定义上传 */
    uploadFile(file) {
      this.loadingTips = true

      importExcel(file.file).then((res) => {
        if (res.code === 1) {
	  var pos = 0
	  var showwarning = false
          for (let i = 0; i < res.data.length; i++) {

	    if(this.noauth_sendnum != '-1' && (pos >=this.have_sendnum)){
		if(showwarning == false){
		  var msg = '没实名认证会员只能发 ' + this.noauth_sendnum + ' 个快递'
		  if(this.noauth_sendnum > this.have_sendnum){
		    msg += '，您已经发了 '+(this.noauth_sendnum-this.have_sendnum)+' 个快递'
		  }
		  this.$message({message:msg, type:'warning',offset:450})
		  this.noauth_warn = msg
		  showwarning = true
		}
		continue
	    }

            this.arsObj.name = res.data[i].name
            this.arsObj.phone = res.data[i].phone
            this.arsObj.province = res.data[i].province
            this.arsObj.city = res.data[i].city
            this.arsObj.area = res.data[i].area
            this.arsObj.detail = res.data[i].detail
            this.arsObj.order = res.data[i].order
            this.arsObj.chk = false
            this.addressList.push(this.arsObj)
            this.arsObj = {}

	    pos++
          }
          this.setOrdersCheck(true);
          this.countPrice();

          this.listTableFlag = true // 显示地址列表
          this.templateImport = false // 上传成功隐藏模板导入
          this.$message({ message: res.msg, type: 'success', duration: 20000 })
        } else {
          return this.$message.error(res.msg)
        }
	this.loadingTips = false
      })
    },
    /* 隐藏仓库选择浮窗  testarea */
    hidePlatformMsg(){
      document.getElementById('onemsg').style.display = "none"
    },
    /* 显示面单提示  testarea */
    showPlatformMsg(tag){
      if(tag != 5)	return;

      const btn = document.getElementById(tag)
      const box = document.getElementById('onemsg')

      box.style.top = ( btn.offsetTop + 52 ) + "px"
      box.style.left = btn.offsetLeft + "px"
      box.style.display = ""
    },
    onCopy(){
	this.$message('复制成功','success')
    },
    onError(){
	this.$message('复制失败','warning')
    },
    // 广告点击
    adClick() {
      window.open(this.clickhref, '_blank')
    },
    // 插件下载
    crxClick() {
      window.open(this.$store.getters.getSiteInfo.download_plugins, '_blank')
    },
    jdClick() {
      window.open(this.$store.getters.getSiteInfo.jd_course, '_blank')
    },
    pddClick() {
      window.open(this.$store.getters.getSiteInfo.pdd_course, '_blank')
    },
    // handleChange(value) {
    handleChange(value, scope) {
      this.num = value
    },
    totalGifts() {
      if (this.addrChkLength !== 0) { //this.addressList.length
        const n = parseFloat(this.goodsItem.goods_price * this.num || 0.0) * parseFloat(this.addrChkLength)
        return n
      } else {
        //const m = parseFloat(this.goodsItem.goods_price * this.num) || 0.0
        //return m || 0.0
	return 0.0
      }
    },
    // 计算运费
    freight() {
      if (this.addrChkLength !== 0) { //this.addressList.length
        const f = parseFloat(this.fprice || 0.0) * parseFloat(this.addrChkLength)
        return f
      } else {
        //const y = parseFloat(this.fprice) || 0.0
        //return y || 0.0
	return 0.0
      }
    },
    countTotalMoney(){
      const t = parseFloat(this.totalGifts()); 
      const e = parseFloat(this.freight())

      document.getElementById('totalMoney').innerHTML = Math.round((t + e)*100)/100;
    },
  },
  /*
  computed: {
    // 计算礼品总额
    totalGifts() {
      if (this.addressList.length !== 0) {
        const n = parseFloat(this.goodsItem.goods_price * this.num || 0.0) * parseFloat(this.addressList.length)
        return n
      } else {
        const m = parseFloat(this.goodsItem.goods_price * this.num) || 0.0
        return m || 0.0
      }
    },
    // // 计算运费
    freight() {
      if (this.addressList.length !== 0) {
        const f = parseFloat(this.fprice || 0.0) * parseFloat(this.addressList.length)
        return f
      } else {
        const y = parseFloat(this.fprice) || 0.0
        return y || 0.0
      }
    },
    // 计算应付金额  {{Number(goods.salePrice).toFixed(2)}}
    payable() {
      const t = parseFloat(this.totalGifts)
      const e = parseFloat(this.freight)
      return t + e
    },
    // 总重量
    totalWeigth() {
      const weight = parseFloat(this.goodsItem.goods_weight) * this.num
      return weight
    },
    // 小计
    littleTotal() {
      const subtotal = parseFloat(this.goodsItem.goods_price) * parseFloat(this.num)
      return subtotal
    }
  },*/
  // 处理插件跳转过来选定店铺
  mounted() {
    const that = this
    const op = that.$route.query.op
    // that.shopId = that.$route.query.shopId
    this.stepTwoActiveShop = -1 // 锁定一键
    this.lurufangshi = -1 // 录入方式
    if (!that.$route.query.shopId) {
      that.$router.push('/orderAdmin/purchase')
    }
    if (op) {
      var num = 0
      var timmer = setInterval(function () {
        if (num < 10) {
          num++
          if (!that.addForm.address) {
            if (that.$refs.seTxt1.value !== undefined) {
              var v = that.$refs.seTxt1.value
            }
            if (v) {
              that.addForm.address = that.$refs.seTxt1.value
              that.addForm.address += '\n'
            }
          } else {
            if (that.addForm.address) {
              clearInterval(timmer)
              that.disAddres()
            }
          }
        } else {
          clearInterval(timmer)
        }
      }, 1000)
      // if (op === 'jd') {
      //   that.stepOneItemClick(2)
      // } else if (op === 'tb') {
      //   that.stepOneItemClick(0)
      // }
    }
    if (that.$route.query.shopId && this.$route.query.item !== '[object Object]') {
      that.lurufangshi = 1
      // that.storeListItemId = that.$route.query.shopId.goods_id
    }
  }
}
</script>

<style scoped>
::v-deep .el-date-editor--daterange.el-input__inner{
    width:380px;
}
</style>
<style scoped src="styles/views/orderAdmin/purchase.css">
.el-message {
  top: 20px !important;
}
.el-message:not(:last-child) {
  visibility: hidden;
}

::v-deep .el-input__inner{
    width:450px !important;
}


/* .el-notification__title { top: 50px !important; } .el-notification__title:not(:last-child){ visibility: hidden; } */
</style>
<style scoped lang="scss">
.el-input {
  width: 450px;
}
.el-button[data-v-11c101ee] {
  width: 150px;
  margin-right: 20px;
}
.el-select[data-v-11c1012b] {
  width: 450px;
}

.layui-menu {
    position: relative;
    margin: 5px 0;
    background-color: #fff;
}
/* 选择面单  */


.onemsg {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  color: #595959;
  text-align: left;
  width: 160px;
  z-index: 999;
  box-shadow: 2px 2px 10px #dadada;
  padding: 8px 20px;
}

/* 仓库列表   */
.storageShow {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  color: #595959;
  text-align: left;
  width: 350px;
  z-index: 999;
  box-shadow: 2px 2px 10px #dadada;
  padding: 5px 0;
}
.storageShow li{
  padding: 5px 20px;
  line-height: 26px;
}
.storageShow li:hover{
  background-color: #f4f4f4;
  cursor: pointer;
}
.storageShow li span{
  font-size:12px;
  color: #666;
  float:right;
}
.stepTitle .ttdesc{
  font-size:16px;
  color: #ff0000;
}
.stepTitle .ttdesc a{
  font-size:16px;
  color: #333399;
}
/* Dialog */
//  .editForm .el-input {
//   width: 100%;
// }
// .list {
//   flex: 1;
//   padding-left: 16px;
//   list-style: none;
//   padding-left: 16px;
//   max-height: 230px;
//   overflow: auto;
// }
</style>
